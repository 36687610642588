
.ReportListItem-ContainerListChannel{
    width: 100%;
    margin-bottom: 10px;
}

.ReportListItem-ContainerListChannel:last-child{
    margin-bottom: 0px;
}

.ReportListItem-ContainerListChannelTitle{
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.ReportListItem-ContainerList{
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    padding-top: 0px;
}

.ReportListItem-Container{
    width: 100%;
    border-radius: 5px;
    border: solid #e7e7e7 2px;
    padding: 15px;
    padding-top: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
}

.ReportListItem-ContainerTitle{
    color: #333333;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    flex: 1 1;
    display: flex;
}

.ReportListItem-ContainerList{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.ReportListItem-ContainerListItem{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.ReportListItem-ContainerListItem:last-child{
    margin-bottom: 0px;
}

.ReportListItem-ContainerListItemChannel{
    height: 55px;
    width: 55px;
    border-radius: 3px;
    margin-right: 10px;
    overflow: hidden;
    box-sizing: border-box;
}

.ReportListItem-ContainerListItemChannelFacebook{
    width: 100%;
    height: 100%;
    background-color: #3A5997;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.ReportListItem-ContainerListItemChannelInstagram{
    width: 100%;
    height: 100%;
    background-color: #DD2A7B;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.ReportListItem-ContainerListItemChannelGoogle{
    width: 100%;
    height: 100%;
    background-color: #4285F4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.ReportListItem-ContainerListItemChannelCampaign{
    width: 100%;
    height: 100%;
    background-color: #388f8a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.ReportListItem-ContainerListItemChannelNone{
    width: 100%;
    height: 100%;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.ReportListItem-ContainerListItemPreview{
    height: 55px;
    width: 55px;
    border-radius: 5px;
    border: solid #e7e7e7 2px;
    overflow: hidden;
    box-sizing: border-box;
}

.ReportListItem-ContainerListItemValues{
    display: flex;
    flex: 1;
    border-radius: 3px;
    border: solid #e7e7e7 1px;
    justify-content: center;
    align-items: center;
    height: 55px;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    background: #fcfcfc;
}

.ReportListItem-ContainerListItemValuesInner{
    display: flex;
    flex: 1;
    flex-direction: row;
}

.ReportListItem-ContainerListItemValuesInnerItem{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ReportListItem-ContainerListItemValuesInnerItemTitle{
    color: #333333;
    font-size: 12px;
    text-align: left;
    font-weight: 200;
    text-transform: capitalize;
}

.ReportListItem-ContainerListItemValuesInnerItemValue{
    color: #333333;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
}

.ReportListItem-ContainerListItemSlider{
    padding-top: 7px;
    cursor: pointer;
}

@media only screen and (max-width: 1050px) {

}


@media only screen and (max-width: 1050px) {

}
