@import "hamburgers";

.adcredo-1{fill:url(#adgradient_14);}.adcredo-2{fill:url(#adgradient_5);}.adcredo-3{fill:#fff;}


body{
  .menu__block--wrapper--item{
    .icon{
      svg{
        width:1rem;
        height:1rem;
        fill:inherit;
      }
    }
  }

  .btn{
    padding:.5rem 1rem;
    background: rgb(91,88,248);
    //font-size:.825rem;
    text-align:center;
    border-radius:.25rem;
    cursor:pointer;
    transition: all .2s ease;
    //font-weight:700;
    box-shadow:none;
    color:white;

    &.btn-lg{
      font-size:.875rem;
      padding:.75rem 2rem;
    }

    &.btn-primary{
      background: linear-gradient(119deg, rgba(91,88,248,1) 0%, rgba(52,46,203,1) 100%);
      
      &:hover{
        background: rgb(37,32,157);
        background: linear-gradient(119deg, rgba(37,32,157,1) 0%, rgba(72,69,215,1) 100%);
        box-shadow:0 8px 30px -10px rgba(157, 156, 249, .25);
      }
    }
  }

  .overlay{
    position:relative;
    z-index:1;
    &::after{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:-1;
      background:theme('colors.purple.900');
      opacity:.8;
    }

    &.overlay--gradient{
      &::after{
        background: linear-gradient(119deg, rgba(37,32,157,1) 0%, rgba(72,69,215,1) 100%);
      }
    }
  }

.sorting-arrows {
    width: 6px;
    height: 12px;
    position: relative;

    &::before,
    &::after{
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      content: '';
      left: 0;
      transition:all .2s ease;
      opacity:.4;
    }

    &::after {
      border-top: 5px solid;
      bottom: 0;
    }

    &::before {
      border-bottom: 5px solid;
      top: 0;
    }
}
.sort-asc{
  .sorting-arrows{
    &::before {
      opacity:1;
    }
  }
}
.sort-desc{
  .sorting-arrows{
    &::after {
      opacity:1;
    }
  }
}
.overflow-scroll{
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.overflow-scroll::-webkit-scrollbar {
  display: none;
}
.btn-snapchat {
  color: #000;
  background-color: #ffc507;
  border-color: #ffc507;
}

.bg-yellow {
  background-color: #ffc507;
}

  button{
    display:inline-block;
  }

  .arrow-right{
    width: 16px;
    height: 2px;
    background: white;
    display: inline-block;
    position: relative;
    margin-left:8px;

    &:after{
      position: absolute;
      width: 8px;
      height: 8px;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: white;
      display: inline-block;
      content: '';
      transform: rotate(-45deg);
      top: 50%;
      right: 0;
      margin-top: -4px;
      margin-right: -1px;
    }

    &.arrow-dark{
      background: #060534;
      &::after{
        border-color: #060534;
      }
    }
  }

  .form-no-borders {
    input{
      border:0 !important;
    }
    .grey-inputs{
      input, .datepicker__block{
        background:#F1F0F8 !important;
      }
    }
    .InputContainerInputContainerSign,
    .TextAreaContainerInputContainerSign{
      color:white;
      background-color:#151517;
    }
  }
  

  @media(max-width:767px){
    .sidenav--wrapper{
      position:fixed;
      top:0;
      left:0;
      right:0;
      z-index:999;

      .menu__block--wrapper{
        position:fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        transition: all .35s ease;
        transform: translateX(100%);
        padding: 5rem 1rem 1rem;
      }

      &.sidenav--wrapper--open{
        .menu__block--wrapper{
          transform: translateX(0);
        }
      }
    }
  }
}