.chart-template-img .chart-template-img__text {
    background-color: white;
    opacity: 0;
}

.chart-template-img:hover > .chart-template-img__text {
    background-color: rgba(255, 255, 255, 0.75);
    opacity: 1;
}

.heading-drop--wrapper:hover .heading-drop--drop {
    display: flex;
}
.heading-drop--drop {
    display: none;
}
